import React from "react";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
} from "react-share";

const iconSize = 36;
const pageURL = "https://www.lovelifelucille.com";

function Quote(props) {
    return (
        <div className="bg-red-100 mb-8 p-4 rounded-lg shadow-md">
            <div className="mx-4 italic text-lll-purple-600">{`"${props.quote}"`}</div>
            <div className="flex flex-row justify-center">
                <FacebookShareButton
                    className="m-2"
                    quote={`"${props.quote}" -Lucille`}
                    url={pageURL}
                    hashtag={"#LoveLifeLucille"}
                >
                    <FacebookIcon
                        size={iconSize}
                        round
                        className="click-track"
                        id={`${props.code}-FB`}
                    />
                </FacebookShareButton>
                <TwitterShareButton
                    className="m-2"
                    title={`"${props.quote}" -Lucille`}
                    via={"JudyGaman"}
                    url={pageURL}
                    hashtags={["LoveLifeLucille"]}
                >
                    <TwitterIcon
                        size={iconSize}
                        round
                        className="click-track"
                        id={`${props.code}-Twitter`}
                    />
                </TwitterShareButton>
            </div>
        </div>
    );
}

export default Quote;
