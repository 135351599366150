import React from "react";

function Review(props) {
  return (
    <div className="bg-red-100 my-auto h-full p-4 rounded-lg shadow-md">
      <div className="mx-4 italic text-lll-purple-600">{`"${props.review}"`}</div>
      <div className="mt-2 mx-4 text-lll-purple-600">{`-${props.author}`}</div>
    </div>
  );
}

export default Review;
