import React from "react";
import Slider from "react-slick";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Layout from "../components/layout";
import SEO from "../components/seo";
import bookCover from "../images/BookCover.jpg";
import BuyButton from "../components/BuyButton";
import Quote from "../components/Quote";
import Review from "../components/Review";
import JudyLucille from "../images/JudyLucille.jpg";

import Amazon from "../images/Amazon.png";
import BarnesNoble from "../images/BarnesNoble.png";
import Apple from "../images/AppleBooks.png";

import Snapcode from "../images/snapcode.png";
import Qrcode from "../images/qr-code.png";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
};

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout>
        <SEO keywords={[`book`, "love", "friendship"]} title="Home" />
        <div>
          <img
            src={bookCover}
            alt="Love, Life, and Lucille Book Cover"
            className="sm:max-w-sm mx-auto shadow-xl"
          />

          <div className="mt-12 px-8">
            <Slider {...sliderSettings}>
              {[
                {
                  review:
                    "Part memoir, part tribute . . . Alternately witty and touching.",
                  author: "Kirkus Reviews",
                },
                {
                  review:
                    "Lucille captured the lives of so many in the Dallas area and around the country. This is a behind the scenes look at how Lucille and Judy forged an inseparable bond. A story that had to be told!",
                  author: "Jane McGarry, host of Good Morning Texas",
                },
                {
                  review:
                    "Grab a tissue and strap in! This emotionally charged memoir will have you busting out laughing one minute and crying the next. Judy paints the picture masterfully and leaves the reader forever touched by Lucille’s wisdom. My listeners still talk about how charming Lucille was, even years after my interviewing her!",
                  author:
                    "Marybeth Conley, anchor for WREG-TV Channel 3’s Live at 9 and host of KWAM990’s The Marybeth Conley Show",
                },
              ].map((review, index) => (
                <Review
                  key={index}
                  review={review.review}
                  author={review.author}
                />
              ))}
            </Slider>
          </div>

          <div className="mt-24 flex flex-col sm:flex-row justify-around items-center sm:max-w-lg mx-auto">
            <OutboundLink
              className="bg-lll-purple-600 hover:bg-lll-purple-700 text-white text-lg font-bold text-center py-4 px-6 rounded-lg shadow"
              href="https://www.judygaman.com/product-page/love-life-and-lucille-lessons-learned-from-a-centenarian"
            >
              {`Buy the book on Judy's site!`}
            </OutboundLink>
          </div>

          <div className="mt-12 flex flex-col sm:flex-row justify-around items-center sm:max-w-lg mx-auto text-lll-purple-600 text-lg">
            {`Also available from`}
          </div>

          <div className="mt-2 flex flex-col sm:flex-row justify-around items-center sm:max-w-lg mx-auto">
            <BuyButton
              name={"Amazon"}
              color="#FF9900"
              image={Amazon}
              link="https://www.amazon.com/Love-Life-Lucille-Lessons-Centenarian/dp/1631528823"
            />
            <BuyButton
              name={"Barnes & Noble"}
              color="#366251"
              image={BarnesNoble}
              link="https://www.barnesandnoble.com/w/love-life-and-lucille-judy-gaman/1132648492"
            />
            <BuyButton
              name={"Apple Books"}
              color="#000000"
              image={Apple}
              link="https://books.apple.com/us/book/love-life-and-lucille/id1474046740"
            />
          </div>

          <div className="mt-8 sm:max-w-sm mx-auto text-lll-purple-600 flex flex-col">
            <h1 className="lll-title font-bold text-center text-lll-purple-600 text-3xl sm:text-4xl">
              {"Have the book?"}
            </h1>
            <p className="text-center">
              Bring the cover to life and hear Lucille in her own voice!
            </p>
            <div className="flex flex-col items-center">
              <div className="flex flex-col items-center my-4">
                <img src={Qrcode} className="w-48" />
                <OutboundLink
                  href="https://www.instagram.com/ar/446643623027327"
                  className="text-xl text-center mx-auto mt-2 font-bold"
                >
                  Click here to try on Instagram!
                </OutboundLink>
              </div>

              <div className="flex flex-col items-center my-4">
                <img src={Snapcode} className="w-48" />
                <OutboundLink
                  href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=6f87214de9354967b0bd20ce68ac0d3f&metadata=01"
                  className="text-xl text-center mx-auto mt-2 font-bold"
                >
                  Click here to try on Snapchat!
                </OutboundLink>
              </div>
            </div>
          </div>

          <div className="sm:max-w-sm mx-auto mt-12">
            <img
              className="mb-6 mx-auto rounded-full"
              alt="Judy and Lucille smiling at each other"
              src={JudyLucille}
            />
            <h1 className="lll-title font-bold text-center text-lll-purple-600 text-3xl sm:text-4xl">
              {"A lasting friendship"}
            </h1>
            <div className="text-lll-purple-600">
              While writing a book on longevity, Judy interviewed Lucille, an
              elegant and spirited woman who had just recently turned 100.
              Lucille had the fashion and style of old Hollywood, but it was all
              hidden behind the doors of her assisted living center. What began
              as a quick meeting became a lasting friendship that transformed
              into an inseparable bond. Lucille brought incredible wisdom and
              great stories to the table, while Judy provided an avenue for
              excitement and new opportunities. Together, the two began living
              life to the fullest, and meeting the most interesting people along
              the way (including Suzanne Somers). But then Lucille’s life came
              to an end through unexpected and unfortunate circumstances―and the
              very first lesson she ever taught Judy proved to be the most
              important one of all.
            </div>
          </div>

          <div className="mt-8 sm:max-w-sm mx-auto">
            <h1 className="lll-title font-bold text-center text-lll-purple-600 text-3xl sm:text-4xl">
              {"In the words of Lucille"}
            </h1>
            <Quote
              quote={
                "I died for 3 minutes and they pumped my chest they hit me and I came to and I said 'Get me to, I want to live! I want to watch the Rangers game!'"
              }
              code={"RangersGame"}
            />
            <Quote
              quote={"Nothing makes you feel better than a waffle."}
              code={"Waffle"}
            />
            <Quote
              quote={"This will be the best day of all."}
              code={"BestDay"}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
