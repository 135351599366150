import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

function BuyButton(props) {
  return (
    <div className="m-4">
      <OutboundLink href={props.link}>
        <img
          src={props.image}
          alt={`Buy the book on ${props.name}`}
          className="w-40"
        />
      </OutboundLink>
    </div>
  );
}

export default BuyButton;
